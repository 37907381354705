<template>
  <div>
    <article-tabs :novo="sidebar.novo" :popularno="sidebar.popularno" :promo="sidebar.promo"/>

    <div class="weatherDiv">
      <VueSlickCarousel v-bind="settings" v-if="sidebar.vrijeme.length">
        <forcast-city v-for="(forcastCity, key) in sidebar.vrijeme" :key="key" :forcastCity="forcastCity"/>
      </VueSlickCarousel>
    </div>

    <adv :ad="ads[21]" class="content"/>

    <span class="clearfix"></span>

    <adv :ad="ads[22]" class="content2"/>
  </div>
</template>

<script>
  import AdvMixin from "../mixins/AdvMixin";
  import Adv from "../components/Adv";
  import ArticleTabs from "../partials/home/ArticleTabs";
  import LangMixin from "../mixins/LangMixin";
  import ForcastCity from "../partials/home/ForcastCity";
  import SlickMixin from "../mixins/SlickMixin";
  import ForcastSlickSettings from "../mixins/ForcastSlickSettings";

  export default {
    mixins: [
      AdvMixin,
      LangMixin,
      SlickMixin,
      ForcastSlickSettings
    ],

    components: {
      Adv,
      ArticleTabs,
      ForcastCity
    },

    data() {
      return {
        sidebar: {
          novo: [],
          popularno: [],
          promo: [],
          vrijeme: [],
        }
      }
    },

    mounted() {
      this.requestSidebarData();
    },

    methods: {
      requestSidebarData() {
        fetch(`${this.$bhrt.api_uri}/sub?lng=${this.lang}`)
            .then(response => response.json())
            .then(data => {
              this.sidebar.novo = data.top4vijesti.data;
              this.sidebar.popularno = data.popular.data;
              this.sidebar.promo = data.promo.data;
              this.sidebar.vrijeme = data.vrijeme.Forecast;
            })
            .catch(error => console.log(error))
      },
    }
  }
</script>