<template>
  <div class="container-fluid">
    <div class="container halfCont" v-if="Object.keys(page).length">
      <div id="contentPage">
        <h1>
          {{ resolveTranslation('Title') }}
        </h1>

        <img :alt="resolveTranslation('Title')" :src="page.MainImage">

        <div v-html="resolveTranslation('Content')" class="mt-3"></div>

        <audio v-for="(audio, key) in page.AudioFile" :key="key" controls :src="audio"></audio>
      </div>

      <article-sidebar v-if="!page.PageTemplate"/>
    </div>
  </div>
</template>

<script>
import eventBus from "../eventBus";
import LangMixin from "../mixins/LangMixin";
import ArticleSidebar from "../components/ArticleSidebar";

export default {
  mixins: [
    LangMixin,
  ],

  components: {
    ArticleSidebar
  },

  data() {
    return {
      page : {}
    }
  },

  mounted() {
    this.requestAudioPageData();

    eventBus.$on('langChange',  ({ lang, history}) => {
      if (! history) {
        this.$router.push(lang === 'lat' ? this.page.Url : this.page.Urlc)
      }
    })
  },

  methods: {
    requestAudioPageData() {
      this.$emit('pageLoading');
      fetch(`${this.$bhrt.api_uri}/resingle?url=${this.$route.params.audio}&parent=${this.$route.params.playlist}`)
        .then(response => response.json())
        .then(data => {
          this.$emit('pageLoaded');
          if (data.error) {
            this.$router.push({ name : 'not_found'})
          }else {
            this.page = data[0];
          }
        })
    },

    resolveTranslation(name) {
      return this.page[`${name}${this.lang.toUpperCase()}`]
    }
  }
}
</script>
